import React from "react";
import "./PaginaConstruccion.css";

export default function PaginaConstruccion() {
  return (
    <>
      <p className="paginaConstruccion">Próximamente 🍎</p>
    </>
  );
}
